<app-header></app-header>

<div class="select-option mt-2">
  <div class="container">
    <div class="row pt-sm-3 pt-sm-5 pt-md-5 pt-lg-5 pb-5">
      <div class="col-12">
        <h1 class="faq-heading mt-4">FAQ’s</h1>
      </div>
      <div class="col-12">
        <hr />
      </div>
      <div class="col-12 p-0 mt-sm-3 mt-sm-5 mt-md-5 mt-lg-5">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="true"
                aria-controls="flush-collapseOne"
              >
                WHO OWNS MY HOME'S SOLAR ENERGY SYSTEM?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <strong>Spruce Power,</strong>
                a leading owner and operator of residential solar systems in the
                United States, is the owner of your system. Various entities
                under the Spruce Power umbrella service the Spruce portfolio of
                residential solar energy systems on behalf of the company.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                WHO DO I CONTACT WITH QUESTIONS ABOUT MY ACCOUNT OR MY HOME
                SOLAR SYSTEM?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Our homeowner support team (HOST) and billing support employees
                are working diligently to provide support for ongoing needs like
                home sales, billing questions, and equipment maintenance. We
                receive a high volume of calls each day, so please be patient as
                we provide support to our network. For questions about your
                account, you can call or email:
                <ul class="mt-3">
                  <li>
                    Phone:
                    <span class="number-color">
                      <a href="tel:+18886360336">888.636.0336</a></span
                    >
                  </li>
                  <li>
                    Email:
                    <span class="email-color"
                      ><a href="mailto:{{ email }}">{{ email }}</a></span
                    >
                  </li>
                  <li>Hours of operation:</li>
                  <ul>
                    <li>Monday-Thursday 8 AM-8 PM Central Time</li>
                    <li>Friday 8 AM-6 PM Central Time</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                WHAT ARE MY ONLINE OPTIONS?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                Spruce customers have access to account management capabilities
                through our
                @if(isUserLoggedIn) {
                  <a class="email-color" [routerLink]="['/dashboard']"
                    >online portal</a
                  >
                }
                @else{
                  <a class="email-color" (click)="login('/dashboard')">online portal</a>
                }
                . Here you can:
                <ul class="mt-2">
                  <li>View self-help and troubleshooting information</li>
                  <li>Contact our Homeowner Support Team</li>
                  <li>Access your account information</li>
                  <li>
                    If you do not have a log in for the service portal, try
                    creating an account through our
                    @if(isUserLoggedIn) {
                      <a class="email-color" href="https://3473540.ecommerce.zuora.com/auth/auth0" target="_blank" rel="noopener"
                        >billing portal</a
                      >
                    }
                    @else{
                      <a class="email-color" (click)="login('https://3473540.ecommerce.zuora.com/auth/auth0')">billing portal</a>
                    }
                    or contacting HOST
                  </li>
                  <li>Access billing and payment options</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                WHAT IS THE BEST WAY FOR ME TO PAY MY MONTHLY BILL?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                You can pay with automatic electronic payments (ACH), check by
                mail, phone payment, or a one-time online payment using Master
                Card, Visa, Discover, or American Express. Automatic electronic
                payments are the recommended method as it will save you time and
                the hassle of sending in a check each month. In addition, you
                may be eligible for automatic payment–related discounts,
                depending on your agreement.
                <h5 class="mt-2">ACH PAYMENTS</h5>
                If you are set up for automatic electronic payments (ACH), your
                payments will be automatically withdrawn from your account each
                month. Customers who previously paid their solar bills through
                online bill-pay may need to update the payment recipient with
                your financial institution to avoid delay in payment application
                to your solar account.
                <h5 class="mt-2">PAY BY CHECK</h5>
                If you choose to pay by mail, please refer to your monthly solar
                invoice for the mailing address and correct payee.
                <h5 class="mt-2">PHONE PAYMENTS</h5>
                You can contact the billing department at
                <span class="number-color">
                  <a href="tel:+18886360336">888.636.0336</a></span> to pay your bill
                by phone. Please note, all payments processed via phone will be
                handled by Spruce Power representatives.
                <h5 class="mt-2">ONLINE PAYMENTS</h5>
                By logging onto our
                @if(isUserLoggedIn){
                  <a href="https://3473540.ecommerce.zuora.com/auth/auth0" class="email-color">online billing portal</a>
                }
                @else {
                  <a (click)="login('https://3473540.ecommerce.zuora.com/auth/auth0')" class="email-color">online billing portal</a>
                }
                  , you can view your monthly solar bills and learn about the different
                  billing options. If you have not set up automatic payments from
                  your bank account already, you can set up a one-time payment or
                  recurring payments within the billing portal
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                HOW ARE MY MONTHLY SOLAR PAYMENTS CALCULATED?
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p>
                  Customers who have a solar lease agreement will pay a set
                  amount each month as outlined in your Agreement. The set
                  amount is for all the energy that the solar system produces
                  each month. The monthly payment amount may increase annually
                  by a set percentage as outlined in your Agreement.
                </p>
                <p>
                  Customers who have a Power Purchase Agreement (PPA) purchase
                  the solar energy that the solar system produces each month.
                  Your solar power is priced at the set dollar per kilowatt hour
                  (kWh) rate outlined in your Solar Agreement. Therefore, your
                  monthly solar payment is the monthly kWh production multiplied
                  by the current price per kWh, plus any applicable taxes and
                  fees. The price per kWh may increase annually by a set
                  percentage as outlined in your Solar Agreement, so please
                  review the specifics for pricing and possible increases.
                </p>
                <p>
                  Please note: The monthly payments you receive will fluctuate
                  throughout the year because the system will not produce the
                  exact same amount of energy every month. The seasons, number
                  of daylight hours, and the weather can all affect power
                  production. If you would like to monitor the production for
                  the system, please go to the Monitoring page in the
                  @if(isUserLoggedIn) {
                    <a [routerLink]="['/monitoring']" class="email-color">Service portal</a>
                  }
                  @else {
                    <a (click)="login('/monitoring')" class="email-color">Service portal</a>
                  }
                  .
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="false"
                aria-controls="flush-collapseSix"
              >
                DO I NEED TO CONTACT YOU WHEN I AM SELLING MY HOME?
              </button>
            </h2>
            <div
              id="flush-collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingSix"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p>
                  We are here to support you if you decide to sell your home
                  during the term of your Solar Agreement. It is important that
                  both you and the buyer understand the home sale options that
                  are outlined in your Solar Agreement. Please review this
                  information for the most accurate and complete description of
                  your home sale options.
                </p>
                <p>
                  It is critical that you notify us ahead of any potential home
                  sale so that we can review the options, requirements, and
                  process with you. We recommend that you contact us at least 30
                  days before the expected close so we can help you understand
                  your options and take the appropriate next steps.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingSeven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSeven"
                aria-expanded="false"
                aria-controls="flush-collapseSeven"
              >
                DOES A UCC FILING MEAN THERE’S A LIEN ON MY HOME?
              </button>
            </h2>
            <div
              id="flush-collapseSeven"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingSeven"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p>
                  There is no lien on your home. Your Solar Provider may have
                  filed a Uniform Commercial Code Financing Statement, aka a
                  UCC-1 Fixture Filing, on the solar system which appears on the
                  title of your home.
                  <strong>This filing is not a lien on your home!</strong>
                  Rather, it is a public record that shows that the solar PV
                  system installed on your home belongs to the Solar Provider.
                </p>
                <p>
                  Some financing providers may ask to see additional
                  documentation about the UCC filing. Please note there is a
                  service charge for the re-filing service. Contact our
                  Homeowner Support Team at
                  <span class="email-color"
                    ><a href="mailto:{{ email }}">{{ email }}</a></span
                  >
                  or call
                  <span class="email-color"
                    ><a href="tel:+18886360336">888.636.0336</a></span
                  >
                  and we’ll walk you the process and answer any other questions
                  you may have about the UCC filing.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEight">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEight"
                aria-expanded="false"
                aria-controls="flush-collapseEight"
              >
                WHAT SHOULD I DO IF THE SYSTEM IS DAMAGED?
              </button>
            </h2>
            <div
              id="flush-collapseEight"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingEight"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p>
                  If you believe that any part of your home’s solar system is
                  damaged, our Homeowner Support Team is here to help. Please
                  contact them at
                  <span class="email-color"
                    ><a href="mailto:{{ email }}">{{ email }}</a></span
                  >
                  or by calling
                  <span class="number-color"
                    ><a href="tel:+18886360336">(888)636-0336</a></span
                  >
                  as soon as possible. A description of the damage, cause, and
                  photos (if possible) of the damage will be requested.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingNine">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseNine"
                aria-expanded="false"
                aria-controls="flush-collapseNine"
              >
                HOW CAN I LIMIT SHADING ON THE SOLAR SYSTEM?
              </button>
            </h2>
            <div
              id="flush-collapseNine"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingNine"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p>
                  Shading is caused by anything that blocks the solar system’s
                  access to the sun which reduces the energy production. Trees
                  are the most common culprit because they continue to grow
                  after the solar system is installed. It is your responsibility
                  to keep trees near the solar system trimmed in order to
                  maximize the energy production. Other obstructions that were
                  not present at the time of installation may also cause the
                  energy production to decrease. Make sure you do not make
                  modifications to your property that may limit the solar
                  system’s access to the sun.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTen">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTen"
                aria-expanded="false"
                aria-controls="flush-collapseTen"
              >
                SHOULD I CLEAN MY SOLAR PANELS?
              </button>
            </h2>
            <div
              id="flush-collapseTen"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTen"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p>
                  Solar panels perform best when they are clean. Dirt, dust,
                  leaves, and bird droppings can decrease the solar system’s
                  energy production. Fortunately, rain will typically clean
                  normal soiling off the system and no other cleaning is
                  required.
                </p>
                <p>
                  If the solar panels are noticeably dirty, you can spray debris
                  off with a garden hose. You should only spray the panels while
                  standing on the ground, and only in the morning or
                  evening.<strong>
                    Never spray the system with water during a hot day as this
                    can cause the panels to crack. Do not use soap, cleaning
                    solutions, or anything besides water on the system.</strong
                  >
                </p>
                <p>
                  If the soiling is significant enough to cause a substantial
                  decrease in energy production, we will determine if a
                  professional cleaning is necessary. If so, we will work with
                  you to schedule a convenient time to send a solar panel
                  cleaning service to your home.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingEleven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEleven"
                aria-expanded="false"
                aria-controls="flush-collapseEleven"
              >
                TROUBLESHOOTING
              </button>
            </h2>
            <div
              id="flush-collapseEleven"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingEleven"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p>
                  We are continuously monitoring your system to assure that
                  everything is properly up and running. In order to do this, we
                  need you to make sure your system’s monitoring equipment stays
                  connected to the internet. Unless your system uses cellular
                  metering, you should maintain a broadband internet connection.
                  Broadband internet, such as DSL or cable, allows us to
                  determine if your system may need maintenance or repairs.
                </p>
                <p>
                  It’s completely normal to see a lag in your production data.
                  Our monitoring systems must collect data from the previous day
                  before providing you with production data, so you may see a
                  lag of 24 hours or more.
                </p>
                <p>
                  Here are a few things that you do to make sure your monitoring
                  connection is working properly:
                </p>
                <ul>
                  <li>
                    If you reset or replace your router, make sure you can see
                    your system’s production data.
                  </li>
                  <li>
                    Ensure all cables and plugs related to the monitoring
                    equipment stay plugged in at all times.
                  </li>
                  <li>
                    Make sure you can see the system’s production data after an
                    internet outage occurs.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-2 mt-lg-5 text-center">
        <h1 class="faq-heading mt-4 text-start">
          Portal Help & Troubleshooting
        </h1>
        <hr />
        <p class="text-start">
          For help with navigating the portal please view our Spruce Customer
          Portal FAQs & Walkthroughs page by clicking the button below
        </p>
        <br />
        <div class="view-button">
          <a [routerLink]="['/portal-faq']">Access Portal Related FAQ's</a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
